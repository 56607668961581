<template>
  <b-row>

    <h2 class="pl-1">Сообщении</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
        md="4"
        class="my-1 px-0"
      >
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Искать"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">Идет загрузка ...</strong>
          </div>
        </template>

        <template #cell(description)="data">
          <span class="text__wrap">{{ data.item.description}}</span>
        </template>

        <template #cell(status)="data">
          <div class="d-flex align-items-center">

            <b-form-checkbox
              class="custom-control-warning"
              name="check-button"
              v-model="data.item.status"
              @change="updateMessageStatus(data.item.id)"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon"/>
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="EyeIcon"/>
              </span>
            </b-form-checkbox>

            <div>
              <span v-if="!data.item.status === false || 0">Новое</span>
              <span v-else>Просмотрено</span>
            </div>

          </div>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <!--    EDIT    -->
            <modal-button
              class="crud__btn"
              ripple="rgba(113, 102, 240, 0.15)"
              size="lg"
              @open-emit="getOneMessage(data.item.id)"
              :name="`modal-edit-${data.item.id}`"
              modalButton="Сохранить"
              :id="data.item.id"
              modal-title="Редактировать"
              variant="flat-warning"
              ref="update-modal-button"
              hide-footer
            >
              <template
                v-slot:button
              >
                <feather-icon
                  icon="EyeIcon"
                  size="21"
                />
              </template>

              <template v-slot:modal-body>
                <div class="d-flex flex-wrap">

                  <!--   DESCRIPTION   -->
                  <b-col cols="12">
                    <b-card>
                      <p>{{ description }}</p>
                    </b-card>
                  </b-col>

                </div>
              </template>

            </modal-button>

          </div>
        </template>

      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  name: "Messages",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    ModalButton,
    ToastificationContent,
    vSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      subscriber_id: null,
      title: '',
      description: '',
      status: false,
      isBusy: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        // {
        //   key: 'subscriber_id',
        //   label: 'ID пользователья',
        // },
        // {
        //   key: 'phone',
        //   label: 'Номер телефона',
        // },
        // {
        //   key: 'title',
        //   label: 'Название',
        // },
        {
          key: 'description',
          label: 'Сообщение',
        },
        {
          key: 'created_at',
          label: 'Время создании',
        },
        {
          key: 'status',
          label: 'Статус',
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      items: [],
      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  async created() {
    await this.getMessages()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  methods: {

    showToast(variant, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      })
    },

    replaceRouter(query) {
      this.$router.replace({query})
        .catch(() => {
        })
    },

    clearInputs() {
      this.title = ''
      this.description = ''
    },

    async getMessages() {
      this.isBusy = true
      await api.messages.fetchMessages()
        .then(res => {
          this.items = res.data.map(item => {
            return {...item, status: !parseInt(item.status)}
          })
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getOneMessage(id) {
      await api.messages.fetchOneMessage(id)
        .then(res => {
          this.description = res.data.description
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    async updateMessageStatus(id) {
      this.isBusy = true
      api.messages.updateMessageStatus(id)
        .then(res => {
          this.getMessages()
          this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
        })
        .catch((error) => {
          console.error(error)
          this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>

//@import '@core/scss/vue/libs/vue-select.scss';

//::v-deep .custom-control-label::before {
//  width: 100px;
//}

.text__wrap {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir] .dropdown-item {
  padding: 0;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}

</style>
